import * as React from "react";
import Layout from "../components/Layout.js";
import AboutUsBanner from "../components/aboutUs/header.js";
import Slider from "../components/aboutUs/slider.js";
import Team from "../components/aboutUs/team.js";
import sissy from "../images/sissy.jpg";
import HeroComponent from "../components/hero/index.js";
import marcus from '../images/marcus.jpeg';
import anika from '../images/anika.jpeg';
import carina from '../images/carina.jpeg';
import hugh from '../images/hugh.jpeg';
import jessica from '../images/jessica.jpeg';
import raghav from '../images/raghav.jpeg';
import ryan from '../images/ryan.jpeg';
import fergus from '../images/fergus.jpeg';
import mathew from '../images/mathew.jpeg';


const AboutPage = () => {
  const member = [
    {
      name: "Marcus Manduci",
      position: "Chairperson",
      photo: marcus,
    },
    {
      name: "Fergus Charles",
      position: "Co-President",
      photo: fergus,
    },
    {
      name: "Anika Sahi",
      position: "Co-President",
      photo: anika,
    },
    {
      name: "Ryan Dharma",
      position: "Treasurer",
      photo: ryan,
    },
    {
      name: "Jessica Zhan",
      position: "Secretary",
      photo: jessica,
    },
    {
      name: "Hugh Barker",
      position: "Vice President Sponsorship",
      photo: hugh,
    },
    {
      name: "Mathew Ho",
      position: "Vice President Operations",
      photo: mathew,
    },
    {
      name: "Sissy Qin",
      position: "Vice President Marketing",
      photo: sissy,
    },
    {
      name: "Carina Ma",
      position: "Vice President Events",
      photo: carina,
    },
    {
      name: "Raghav Ramanathan",
      position: "Vice President IT",
      photo: raghav,
    },
  ];

  return (
    <Layout>
      <HeroComponent
        page={"About"}
        heading={"Who are we"}
        subheading={"Learn about who we are and what we do."}
        punctuation={"?"}
      ></HeroComponent>
      <AboutUsBanner
        bgcolor={"lightBlue"}
        heading={"About Us"}
        text={
          "Combining both technical and interpersonal skills, a COMM-STEM degree lays the solid foundation to succeed in both today and tomorrow's world."
        }
      />
      <Slider></Slider>
      <Team header={"Meet the Team"} member={member} />
      {/* <h2 className="text-center font-bold font-body text-4xl xl:text-6xl text-blue">
        Past events
      </h2> */}
    </Layout>
  );
};

export default AboutPage;
