import React from "react";

const Team = ({ member, header }) => {
  return (
    <div
      className={
        "flex items-center box-border w-full px-11 flex-col my-20 justify-center"
      }
    >
      <h2
        className={`font-bold my-8 font-body text-3xl md:text-5xl lg:text-6xl xl:text-7xl text-blue`}
      >
        {header}
      </h2>
      <div className={"flex w-full flex-wrap flex-row justify-center"}>
        {member.map((val) => (
          <div
            className={`flex w-80 mx-2 my-2 justify-center flex-col items-center p-2`}
          >
            <div className={`w-60 h-60  rounded-full my-4 overflow-hidden`}>
              <img src={val.photo} className="w-full h-full" />
            </div>
            <div className={`font-bold text-xl`}>{val.position}</div>
            <div className={`font-medium text-base`}>{val.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
