import * as React from "react";

const AboutUsBanner = ({ text, heading }) => {
  return (
    <div className={`flex items-center justify-center w-full h-80 flex-col `}>
      <h2
        className={`text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-bold pb-5 lg:pb-10 font-body text-blue`}
      >
        {heading}
      </h2>
      <div
        className={`flex items-center justify-center bg-blue w-full flex-col px-11 py-10 lg:py-16`}
      >
        <p className="font-body text-center font-lexend-deca text-white text-lg md:text-xl xl:text-2xl">
          {text}
        </p>
      </div>
    </div>
  );
};

export default AboutUsBanner;
